<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.ENTERPRICE.STORE.PRODUCT_CONDITION') }}
              </h3>
            </div>
            <div class="card-button">
              <conditionOfGoodsCreate />
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="getAllGoods"
              hide-default-footer
              single-select
              @click:row="rowClick"
              item-key="id"
              class="elevation-1 row-pointer"
            >
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <conditionOfGoodsAction
                    :index="item"
                  ></conditionOfGoodsAction>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import conditionOfGoodsCreate from './conditionOfGoodsCreate'
import conditionOfGoodsAction from './conditionOfGoodsAction'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1
    }
  },
  created() {
    this.$store.dispatch('allConditionOfGoods')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: this.$t('TABLE_HEADER.CODE'),
          value: 'code'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getAllGoods() {
      return this.$store.state.conditionOfGoods.allConditionGoods
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0

        row.select(false)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.STORE.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.STORE.PRODUCT_CONDITION') }
    ])
  },
  components: {
    conditionOfGoodsCreate,
    conditionOfGoodsAction
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
