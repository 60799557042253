<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="primary" v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.STORE.PRODUCT_CONDITION') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Tovarlar holati qo'shish</span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex">
            <div class="col-6 pl-0">
              <v-text-field
                :error-messages="nameInputErrors"
                :success="
                  nameInput !== '' &&
                  nameInput !== null &&
                  nameInput !== undefined
                "
                label="Nomi"
                v-model="nameInput"
                outlined
                dense
                @input="$v.nameInput.$touch()"
                @blur="$v.nameInput.$touch()"
              ></v-text-field>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                :error-messages="codeInputErrors"
                label="Kod"
                v-model="codeInput"
                v-mask="'####'"
                counter="4"
                outlined
                dense
                :success="Boolean(codeInput)"
                @input="$v.codeInput.$touch()"
                @blur="$v.codeInput.$touch()"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="newGoodsconditionLoading"
            @click="dialog = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            color="primary"
            :disabled="newGoodsconditionLoading"
            @click="submit"
          >
            <i v-if="newGoodsconditionLoading" class="el-icon-loading"></i>
            Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      newGoodsconditionLoading: false,
      dialog: false,
      nameInput: '',
      codeInput: ''
    }
  },
  validations: {
    nameInput: {
      required
    },
    codeInput: {
      required
    }
  },
  computed: {
    nameInputErrors() {
      const errors = []
      if (!this.$v.nameInput.$dirty) return errors
      !this.$v.nameInput.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    codeInputErrors() {
      const errors = []
      if (!this.$v.codeInput.$dirty) return errors
      !this.$v.codeInput.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          name: this.nameInput,
          code: this.codeInput
        }
        this.newGoodsconditionLoading = true
        this.$store
          .dispatch('conditionOfGoodsCreate', data)
          .then(() => {
            this.newGoodsconditionLoading = false
            this.$v.$reset()
            this.dialog = false
            this.nameInput = ''
            this.codeInput = ''
          })
          .catch((err) => {
            this.newGoodsconditionLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>

<style></style>
