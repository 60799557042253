<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex">
            <div class="col-6 pl-0">
              <div v-if="getData !== undefined">
                <v-text-field
                  outlined
                  dense
                  label="Nomi"
                  v-model="getData.name"
                ></v-text-field>
              </div>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                outlined
                dense
                label="Kod"
                v-model="getData.code"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">Yopish</v-btn>
          <v-btn color="primary" @click="submitUpdate">Saqlash</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data2: {},
      data3: this.actionObj,
      main_type: ''
    }
  },
  created() {
    this.$store.dispatch('allBasicCost')
  },
  computed: {
    getData() {
      const data3 = {
        name: this.actionObj.name,
        code: this.actionObj.code
      }
      return data3
    },
    getDataName() {
      // this.$store.state.expenses.allBasicCost.forEach(element => {
      //   if (element.id == this.actionObj.main_type) {
      //     const newobj = {
      //       name: element.name,
      //       id: element.id
      //     };
      //   }
      // });
      return this.$store.state.expenses.allBasicCost.find(
        (obj) => obj.id == this.actionObj.main_type
      )

      // return newobj;
    },
    allMainExpenditures() {
      return this.$store.state.expenses.allBasicCost
    }
  },
  methods: {
    submitUpdate() {
      const id = {
        id: this.actionObj.id
      }
      const data = {
        name: this.getData.name,
        code: this.getData.code
      }
      this.$store.dispatch('conditionOfGoodsUpdate', { id, data })
      this.dialog = false
    }
  },
  props: {
    actionObj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
