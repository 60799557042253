<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <!-- <router-link :to="'/actrecondetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text ">{{ $t('ACTION.DETAILS') }} </span>
        </router-link> -->
        <div class="component">
          <Update :actionObj="index" />
        </div>
        <div class="component">
          <Delete :actionObj="index" />
        </div>
        <!-- <router-link :to="'/actrecondetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-close text-danger"></i>
          </span>
          <span class="navi-text ">O'chirish</span>
        </router-link> -->
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
import Update from './update'
import Delete from './delete'
export default {
  data() {
    return {
      dialog: false
    }
  },
  name: 'mainExpendituresAction',
  props: {
    index: Object
  },
  computed: {},
  components: { Update, Delete },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.component {
  cursor: pointer;
}
.btn_submit {
  background-color: #007bff;
  color: rgb(255, 255, 255) !important;
}
.headline__text {
  font-size: 1rem !important;
  color: #000;
  font-weight: bold;

  text-decoration: underline;
}
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
